<script>
export default {
  name: 'AboutProject',
  page: {
    title: 'Περιγραφή'
  }
}
</script>

<template>
  <div>
    <div class="page-header">
      <b-container class="bv-example-row">
          <h1 class="title">Περιγραφή</h1>
      </b-container>
    </div>
    <div class="page-body">
      <b-container class="bv-example-row">
        <p class="page-text">Το έργο IGNITE αποσκοπεί στο σχεδιασμό, την ανάπτυξη και την υποστήριξη της λειτουργίας παιγνιώδους δραστηριότητας στο πλαίσιο της εκπαιδευτικής δραστηριότητας.
          Το έργο υιοθετεί μία καινοτομική προσέγγιση gamification, βάσει της οποίας το σενάριο ροής διαπερνά οριζόντια τη λειτουργία του Οργανισμού, συμπεριλαμβανομένων τόσο των επιμέρους διεπιστημονικών περιοχών όσο και των σχετιζόμενων διοικητικών και διαχειριστικών λειτουργιών του εκπαιδευτικού φορέα.
        </p>
        <p>
          Το έργο εκτιμάται ότι έχει ιδιαίτερη εφαρμογή στο πλαίσιο της μεταλυκειακής επαγγελματικής εκπαίδευσης και κατάρτισης με απτά αποτελέσματα:
        </p>
        <ul>
          <li>την προσέγγιση των σπουδαστών και την ενθάρρυνση της ενεργητικής συμμετοχής (engagement) τους στις εκπαιδευτικές δραστηριότητες,</li>
          <li>την ενίσχυση και υποστήριξη αυτής καθαυτής της μαθησιακής διαδικασίας, κατά κύριο λόγο μέσω της ενδυνάμωσης του στοιχείου της συνεργατικής μάθησης,</li>
          <li>τη διασύνδεση με τις διοικητικές πτυχές της εκπαιδευτικής λειτουργίας (βαθμολόγηση, αποφοίτηση, κλπ.) και τέλος</li>
          <li>τη βελτιστοποίηση του επιπέδου των υπηρεσιών που παρέχονται από τον εκπαιδευτικό οργανισμό στους σπουδαστές.</li>
        </ul>
      </b-container>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use './../../assets/scss/abstracts' as *;

.page-header {
  background-color: $white;
  padding: $s-xl 0 $s-s 0;
}

.title {
  color: $black;
  font-family: "Waiting for the Sunrise", cursive;
  padding: $s-s 0;
}

.page-body {
  background-color: $alto;
  color: $black;
  padding: $s-xl 0;
}

.page-text {
  line-height: $s-xl;
}
</style>
